import React from "react"
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import ContactContent from "../components/contactContent/index";

function Contact({location, lang}) {
  return(
    <div>
      <Helmet>
        <title>Voa Labs | Services</title>
        <meta name="description" content="Voa Labs strategizes, designs & builds digital experiences. We are a digital product studio from Switzerland." />
      </Helmet>
      <ContactContent lang={lang} location={location} />
    </div>
  )
}

Contact.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default Contact;