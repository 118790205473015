import React from "react"
import Layout from "../layout"

import "./../layout.scss"
import './styles.scss'


function ContactContent({ lang, location }) {

    const dictionaryList = {
        en: {
            title: 'Let’s work together',
            text: 'Have a question? Write us and we’ll get back to you as soon as possible.'
        },
        de: {
            title: 'Lass uns zusammenarbeiten',
            text: 'Schreib uns eine Email, und wir werden uns so schnell wie möglich mit dir in Verbindung setzen.'
        }
    };

    let content;
    lang === "de" ? (content = dictionaryList.de) : (content = dictionaryList.en)

    return (
        <div>
            <Layout lang={lang} location={location}>
                <section className="section content-section contact-section">
                    <div className="container">
                        <div className="columns reverse-columns">
                            <div class="column is-5">
                            <img alt="Contact Voa Labs" src="/img/contact.jpg" />
                            </div>
                            <div class="column" className="contact-section--content">
                                <h1 className={`${lang !== "de" ? 'is-underline' : '' }`}>{content.title}</h1>
                                <p className="contact-section--text">
                                    {content.text}
                                </p>
                                <p>
                                    <a href="mailto:hello@voalabs.com">hello@voalabs.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </div>
    )
}

export default ContactContent;